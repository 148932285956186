import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Button, Col, Row } from 'react-bootstrap';

import '../generic-page.scss';
import './play-on-pc.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';

const LimbuslayonPCPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page play-on-pc-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>Play on PC</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/limbuscompany/categories/category_pc.jpg"
            alt="PC"
          />
        </div>
        <div className="page-details">
          <h1>Play Limbus Company on PC with Bluestacks</h1>
        </div>
      </div>
      <div className="page-content">
        <div className="banner-limbus">
          <div className="cta">
            <h4>Play Limbus Company on PC!</h4>
            <OutboundLink href="https://bstk.me/mGKpobpBu" target="_blank">
              <Button variant="primary">Play now on Bluestacks</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="Play on PC" />
        <p>
          Limbus Company is a Role Playing game created by Project Moon.
          BlueStacks app player is the best platform to play this Android game
          on your PC or Mac for an immersive gaming experience.
        </p>
        <h6>What is BlueStacks?</h6>
        <p>
          BlueStacks is an Android emulator available on Windows and macOS.
          Instead of playing your favorite games on your phone, you can do it
          straight from your PC!
        </p>
        <SectionHeader title="Benefits of using BlueStacks" />
        <p>
          While Limbus Company has an official PC client available on STEAM,
          playing on BlueStacks gives you a few advantages:
        </p>
        <ul>
          <li>
            It's not possible to reroll on the STEAM client. Rerolling allows
            you to get an optimal start!
          </li>
          <li>
            BlueStacks supports multi-instances, so if you're playing multiple
            accounts or want to reroll in a more optimal way, BlueStacks easily
            allows you to do it,
          </li>
          <li>
            BlueStacks allows you to use Macros to optimize your experience -
            you can save a lot of time by creating macros that do the boring
            stuff for you!
          </li>
        </ul>
        <SectionHeader title="Multi-instance" />
        <p>
          If you want to save time while rerolling for your favorite characters,
          then the multi-instance feature available in BlueStacks is perfect for
          you! But it also comes in handy when you play multiple games and want
          to do it at the same time!
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="N2A5WtX2AVA" />
          </Col>
        </Row>
        <SectionHeader title="Recommended PC configuration" />
        <p>
          The following specifications are recommended for playing one or two
          instances:
        </p>
        <ul>
          <li>OS: Microsoft Windows 10 and above,</li>
          <li>
            Processor: Intel or AMD Multi-Core Processor with Single Thread
            benchmark score &gt; 1000
          </li>
          <li>
            Graphics: Intel/Nvidia/ATI, Onboard or Discrete GPU with benchmark
            score &gt;= 750
          </li>
          <li>
            Please ensure{' '}
            <a
              href="https://support.bluestacks.com/hc/en-us/articles/4409279876621"
              target="_blank"
              rel="noreferrer"
              className="social-media-link"
            >
              Virtualization is enabled
            </a>{' '}
            on your PC/Laptop.
          </li>
          <li>RAM: 8GB or higher</li>
          <li>Storage: SSD (or Fusion/Hybrid Drives)</li>
        </ul>
        <p>
          To find the benchmark score of your processor (CPU) and graphics card
          (GPU), read this{' '}
          <a
            href="https://support.bluestacks.com/hc/en-us/articles/360061491412"
            target="_blank"
            rel="noreferrer"
            className="social-media-link"
          >
            article
          </a>{' '}
          .
        </p>
        <SectionHeader title="Play Limbus Company and support Prydwen" />
        <p>
          If you download BlueStacks using our promotional link and play Limbus
          Company (or other games) on it, you will support the website. Thank
          you!
        </p>
        <div className="banner-limbus">
          <div className="cta">
            <h4>Play Limbus Company on PC!</h4>
            <OutboundLink href="https://bstk.me/mGKpobpBu" target="_blank">
              <Button variant="primary">Play now on Bluestacks</Button>
            </OutboundLink>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default LimbuslayonPCPage;

export const Head: React.FC = () => (
  <Seo
    title="Play on PC | Limbus Company | Prydwen Institute"
    description="Play Limbus Company gacha game on your PC."
    game="limbus"
  />
);
